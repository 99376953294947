import '../styles/Categorypage.css'
import Logo from "../assets/content_hub_banner.jpg";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getContent} from "../api/api";
import Footer from "./Footer";
import Content from "./Content";
import ArticleCategoryPage from "./ArticleCategoryPage";
import TopMenu from "./TopMenu";


const CategoryPage = () => {
    const [content, setContent] = useState([]);
    const {category} = useParams();

    const slicePost = function (data) {
        for (let i=0; i < Object.keys(data).length; i++){
            const index = Object.keys(data)[i];
            data[index].content = data[index].content.slice(0,180) + "[...]"
        }
        return data
    };

    useEffect(()=>{
        (async ()=>{
            const data = await getContent();
            const newContent = slicePost(data);
            setContent(newContent);
        })();
    },[category]);

    return (
        <div>
            <TopMenu back_image={Logo} content={content}/>
            <div className='category-title'>CATEGORY: {category.toUpperCase()}</div>
            <Content>
                {content ?
                    Object.keys(content).map(key => {
                        if (content[key].category === category.toLowerCase()) {
                            return <ArticleCategoryPage key={key} id={content[key].id} title={content[key].title}
                                                 content={content[key].content} image={content[key].image}/>
                        }
                    })
                : ''}
            </Content>
            <Footer/>
        </div>
    );
};

export default CategoryPage