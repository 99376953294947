import '../styles/Footer.css'
import Logo from "../assets/content_hub_banner.jpg"
import TopMenu from "./TopMenu";
import Footer from "./Footer";


const PrivacyPolicy = () => {
    return (
        <div>
            <TopMenu back_image={Logo}/>
            <div className='footer-tab-container'>
                <div className='footer-title'> <span>PRIVACY POLICY</span></div>
                <div className='privacy-policy-content'>
                    <p>
                        At Best Content Hub, accessible from http://best-content-hub.com/, one of our main priorities is the privacy of<br/>
                        our visitors. This Privacy Policy document contains types of information that is collected and<br/>
                        recorded by Best Content Hub and how we use it.<br/>
                        If you have additional questions or require more information about our Privacy Policy, do not hesitate<br/>
                        to contact us.<br/>
                        This Privacy Policy applies only to our online activities and is valid for visitors to our website with<br/>
                        regards to the information that they shared and/or collect in Best Content Hub. This policy is not<br/>
                        applicable to any information collected offline or via channels other than this website.<br/>
                        <b>Consent</b> <br/>
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                    </p>

                    <p>
                       <b>Information we collect</b> <br/>
                    The personal information that you are asked to provide, and the reasons why you are asked to <br/>
                    provide it, will be made clear to you at the point we ask you to provide your personal information.<br/>
                    If you contact us directly, we may receive additional information about you such as your name, email<br/>
                    address, phone number, the contents of the message and/or attachments you may send us, and any<br/>
                    other information you may choose to provide.<br/>
                    When you register for an Account, we may ask for your contact information, including items such as<br/>
                    name, company name, address, email address, and telephone number.
                    </p>
                    <p>
                        <b> How we use your information </b>
                        We use the information we collect in various ways, including to: <br/>
                         Provide, operate, and maintain our website <br/>
                         Improve, personalize, and expand our website <br/>
                         Understand and analyze how you use our website <br/>
                         Develop new products, services, features, and functionality  <br/>
                         Communicate with you, either directly or through one of our partners, including for customer<br/>
                        service, to provide you with updates and other information relating to the website, and for<br/>
                        marketing and promotional purposes <br/>
                         Send you emails <br/>
                         Find and prevent fraud
                    </p>
                    <p>
                        <b>Log Files</b><br/>
   n                     Best Content Hub follows a standard procedure of using log files. These files log visitors when they visit<br/>
                        websites. All hosting companies do this and a part of hosting services&#39; analytics. The information<br/>
                        collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider<br/>
                        (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not<br/>
                        linked to any information that is personally identifiable. The purpose of the information is for<br/>
                        analyzing trends, administering the site, tracking users&#39; movement on the website, and gathering<br/>
                        demographic information.
                    </p>

                    <p>
                       <b> Cookies and Web Beacons</b> <br/>
                        Like any other website, Best Content Hub uses &#39;cookies&#39;. These cookies are used to store information<br/>
                        including visitors&#39; preferences, and the pages on the website that the visitor accessed or visited. The<br/>
                        information is used to optimize the users&#39; experience by customizing our web page content based on<br/>
                        visitors&#39; browser type and/or other information.<br/>
                    </p>

                    <p>
                        <b> Google DoubleClick DART Cookie </b> <br/>
                        Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to<br/>
                        serve ads to our site visitors based upon their visit to www.website.com and other sites on the<br/>
                        internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad<br/>
                        and content network Privacy Policy at the following URL<br/>
                        –https://policies.google.com/technologies/ads<br/>
                    </p>

                    <p>
                       <b> Our Advertising Partners </b> <br/>
                        Some of advertisers on our site may use cookies and web beacons. Our advertising partners are<br/>
                        listed below. Each of our advertising partners has their own Privacy Policy for their policies on user<br/>
                        data. For easier access, we hyperlinked to their Privacy Policies below.<br/>
                         Google - <br/>
                        https://policies.google.com/technologies/ads

                    </p>

                    <p>
                        <b> Advertising Partners Privacy Policies </b> <br/>
                        You may consult this list to find the Privacy Policy for each of the advertising partners of<br/>
                        Best Content Hub.<br/>
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons<br/>
                        that are used in their respective advertisements and links that appear on Best Content Hub, which are<br/>
                        sent directly to users'; browser. They automatically receive your IP address when this occurs. These <br/>

                       <br/> technologies are used to measure the effectiveness of their advertising campaigns and/or to<br/>
                        personalize the advertising content that you see on websites that you visit.<br/>
                        Note that Best Content Hub has no access to or control over these cookies that are used by third-party<br/>
                        advertisers.
                    </p>

                    <p>
                        <b> Third Party Privacy Policies</b> <br/>
                        Best Content Hub's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising<br/>
                        you to consult the respective Privacy Policies of these third-party ad servers for more detailed<br/>
                        information. It may include their practices and instructions about how to opt-out of certain options.<br/>
                        You can choose to disable cookies through your individual browser options. To know more detailed<br/>
                        information about cookie management with specific web browsers, it can be found at the browsers';<br/>
                        respective websites.<br/>

                    </p>

                    <p>
                        <b>CCPA Privacy Rights (Do Not Sell My Personal Information)</b> <br/>
                        Under the CCPA, among other rights, California consumers have the right to:<br/>
                        Request that a business that collects a consume's personal data disclose the categories and<br/>
                        specific pieces of personal data that a business has collected about consumers.<br/>
                        Request that a business delete any personal data about the consumer that a business has collected.<br/>
                        Request that a business that sells a consumer's personal data, not sell the consumer's personal<br/>
                        data.<br/>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of<br/>
                        these rights, please contact us.<br/>
                    </p>

                    <p>
                        <b> GDPR Data Protection Rights </b> <br/>
                        We would like to make sure you are fully aware of all of your data protection rights. Every user is<br/>
                        entitled to the following:<br/>
                        The right to access – You have the right to request copies of your personal data. We may charge<br/>
                        you a small fee for this service.<br/>
                        The right to rectification – You have the right to request that we correct any information you believe<br/>
                        is inaccurate. You also have the right to request that we complete the information you believe is<br/>
                        incomplete.<br/>
                    </p>

                    <p>
                        The right to erasure – You have the right to request that we erase your personal data, under certain<br/>
                        conditions.<br/>
                        The right to restrict processing – You have the right to request that we restrict the processing of your<br/>
                        personal data, under certain conditions.<br/>
                        The right to object to processing – You have the right to object to our processing of your personal<br/>
                        data, under certain conditions.<br/>
                        The right to data portability – You have the right to request that we transfer the data that we have<br/>
                        collected to another organization, or directly to you, under certain conditions.<br/>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of<br/>
                        these rights, please contact us.<br/>
                    </p>

                    <p>
                       <b> Children's Information </b> <br/>
                        Another part of our priority is adding protection for children while using the internet. We encourage<br/>
                        parents and guardians to observe, participate in, and/or monitor and guide their online activity.<br/>
                        Best Content Hub does not knowingly collect any Personal Identifiable Information from children under<br/>
                        the age of 13. If you think that your child provided this kind of information on our website, we strongly<br/>
                        encourage you to contact us immediately and we will do our best efforts to promptly remove such<br/>
                        information from our records.<br/>
                    </p>

                    <p>
                        <b> For any concern, please contact us at admin@best-content-hub.com </b>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default PrivacyPolicy